<!--
 * @Description: 冻结
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-04-30 10:01:11
 * @LastEditors: Seven
 * @LastEditTime: 2021-12-02 17:46:16
-->
<template>
  <div class="frost">
    <div ref="contentTop">
      <el-row
        :gutter="10"
        style="width: 94%; margin: 0 auto"
      >
        <el-col>
          <el-button v-if="permUtil.WbCusotmer.freezeRelease()" :disabled="currList.length === 0" @click="giveUp" style="margin-top: 10px" type="primary" class="hq-button" icon="el-icon-upload">释放</el-button>
        </el-col>
        <el-col>
          <Search
            v-if="searchParamList.length > 0"
            :searchAllParam="searchAllParam"
            @search="search"
            @reset="reset"
            @searchMore="searchMore"
            @searchList="searchList"
            :searchParamList="searchParamList"
            :searchParamFromApi="searchParamFromApi"
            code="frost"
          />
        </el-col>
      </el-row>
      <div class="line"></div>
    </div>
    <el-row style="width: 94%; margin: 0 auto">
      <el-col>
        <el-table
          highlight-current-row
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          :height="tableHeight"
          size="mini"
          v-loading="listLoading"
          element-loading-text="拼命加载中"
          :row-style="{ height: '0px' }"
          :cell-style="{ padding: '0px' }"
          :header-cell-style="{ background: '#e5f2ff' }"
          @selection-change="selectionRow"
          @sort-change="sortChange"
        >
          <el-table-column
            type="selection"
            width="55"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="学员姓名"
            prop="name"
            min-width="100"
            
          >
            <template slot-scope="scope">
              <el-button type="text" @click="openDetail(scope.row)">
                {{ scope.row.name }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="部门"
            prop="deptName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="冻结时间"
            prop="freezeTime"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="释放时间"
            prop="releaseTime"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="最后归属销售"
            prop="userName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column
            label="操作"
            prop=""
            min-width="80"
            
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="columnGiveUp(scope.row)">释放</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="page_test_bottom">
          <el-pagination
            background
            @size-change="sizeChange"
            @current-change="currentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="pageParam.pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageParam.pageSize"
            :total="total"
          />
        </div>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <Detail ref="drawer" :method="getFreezeList" />
  </div>
</template>
<script>
import Search from "components/search";
import Detail from "./components/detail";
import { getUserCustomQuery, getDeptList, getUserList } from "api/public";
import { getFreezeList, doFreezeRelease } from "api/customer";

export default {
  name: "frost",
  components: {
    Search,
    Detail,
  },
  data() {
    return {
      activeName: "all",
      tabList: [
        { name: "所有", path: "all" },
        { name: "未质检", path: "noinspect" },
        { name: "已质检", path: "inspect" },
        { name: "无需质检", path: "none" },
      ],
      searchAllParam: [
        {name: 'name', label: '学员姓名', type: "input", value: ""},
        {name:'phone', label: '手机', type: "input", value: ""}, 
        {name: 'deptId', label: '部门', type: "tree", value: ""}, 
        { 
          name: 'freezeTime', 
          label: '冻结时间', 
          type: "date", 
          value: [],
          ref: "freeze",
          show: false
        },
        {
          name: 'releaseTime', 
          label: '释放时间', 
          type: "date", 
          value: [],
          ref: "release",
          show: false
        }, 
        {name: 'userId', label: '最后归属销售', type: "select", value: "", option: [], text: "name", id: "id"}, 
        {name: 'weiXin', label: '微信', type: "input", value: ""},
        {name:'qq', label: 'QQ', type: "input", value: ""},
      ],
      searchParamList: [],
      defaultParamList: ["name", "phone", "deptId"],
      searchParamFromApi: [],
      tableData: [],
      listLoading: false,
      total: 0,
      pageParam: {
        pageNum: 1,
        pageSize: 20,
        sortItems: [
          {
            asc: false,
            column: ""
          }
        ],
      },
      currList: [],
      searchParam: {},
      // 页面是否有滚动条
      isScroll: true,
      topHeight: 0,
    };
  },
  computed: {
    tableHeight() {
      return window.outerHeight - this.topHeight - 220 + 'px'
    }
  },
  mounted() {
    this.getSearchList()
    this.getFreezeList()
    this.searchAllParam.map(item => {
      if(item.name === "deptId") {
        getDeptList().then(data => item.option = data.result)
      } else if(item.name === "userId") {
        getUserList().then(data => item.option = data.result)
      }
    })
  },
  methods: {
    // tab切换
    handleClick(index) {
      this.activeName = index;
      this.$router.push({ query: { type: this.activeName } });
    },
    // 更新自定义查询
    searchList() {
      this.getSearchList()
    },
    // 更多查询条件
    searchMore(value) {
      this.searchParamList = value
        ? this.searchAllParam
        : this.defaultParamList;
      this.$nextTick(() => {
        this.topHeight = this.$refs.contentTop.offsetHeight
      })
    },
    // 获取自定义查询的默认查询字段
    async getSearchList() {
      const {code, result } = await getUserCustomQuery('frost');
      if(code === 0) {
        this.defaultParamList = ["name", "phone", "deptId"]
        if (result && result.fieldData && JSON.parse(result.fieldData).length > 0) {
          this.searchParamFromApi = JSON.parse(result.fieldData)
          
          this.defaultParamList = []
          this.searchParamFromApi.map(() => {
            this.defaultParamList.push("")
          })
          let index
          this.searchAllParam.filter((item) => {
            index = this.searchParamFromApi.findIndex(i => i === item.name)
            this.defaultParamList[index] = item
          });
        } else {
          this.defaultParamList = this.searchAllParam.filter((item) =>
            this.defaultParamList.includes(item.name)
          );
        }
        this.searchParamList = this.defaultParamList;
        this.$nextTick(() => {
          this.topHeight = this.$refs.contentTop.offsetHeight + 40
        })
      }
    },
    // 单条释放
    columnGiveUp(row) {
      this.giveUpList([row])
    },
    giveUp() {
      this.giveUpList(this.currList)
    },
    // 批量释放
    giveUpList(list) {
      let params = []
      list.forEach(item => {
        params.push({customerId: item.customerId, id: item.id})
      })
      this.$hqMessageBox({
        message:
          "商机未到解冻时间，确定将所选多条商机释放？",
      })
      .then(async () => {
        const { code, msg } = await doFreezeRelease(params)
        if(code === 0) {
          this.$message.success(msg)
          this.getFreezeList()
        }
      })
      .catch(() => {
        console.log("取消");
      })
    },
    // 打开详情
    openDetail(row) {
      this.$refs.drawer.init(row)
    },
    // 获取列表数据
    async getFreezeList() {
      this.listLoading = true;
      const { code, result, msg } = await getFreezeList({...this.pageParam, ...this.searchParam});
      if(code === 0) {
        this.tableData = result.list;
        this.total = result.total;
        this.$nextTick(() => {
          this.isScroll = document.body.scrollHeight > window.innerHeight
        })
      } else {
        this.$message.error(msg)
      }
      this.listLoading = false;
    },
    // 多选事件--
    selectionRow (val) {
      this.currList = val;
    },

    // 改变分页条数
    sizeChange(val) {
      this.pageParam.pageSize = val;
      this.getFreezeList()
    },

    // 改变分页
    currentChange(val) {
      this.pageParam.pageNum = val;
      this.getFreezeList()
    },
    // 排序
    sortChange(val) {
      this.pageParam.sortItems[0].column = val.prop;
      this.getFreezeList();
    },
    // 查询
    search() {
      this.searchParam = {}
      this.searchParamList.map(item => {
        if(item.value  || ( Array.isArray(item.value)  && item.value.length)) {
          if(item.type === "date") {
            if(item.name === "freezeTime") {
              this.searchParam.freezeBeginTime = item.value[0];
              this.searchParam.freezeEndTime = item.value[1];
            } else if(item.name === "releaseTime") {
              this.searchParam.releaseBeginTime = item.value[0];
              this.searchParam.releaseEndTime = item.value[1];
            }
          } else {
            this.searchParam[item.name] = item.value 
          }
        }
      })
      this.getFreezeList()
    },
    // 重置
    reset() {
      this.searchParamList.map(item => {
        if(item.value || item.value == 0) {
          if(item.name === "freezeTime" ||
          item.name === "releaseTime") {
            item.value = []
          } else {
            item.value = "" 
          }
        }
      })
      this.pageParam = {
        pageNum: 1,
        pageSize: 10,
        sortItems: [
          {
            asc: false,
            column: ""
          }
        ],
      }
      this.search()
    },
  },
};
</script>
<style lang="scss" scoped>
.frost {
  // padding-bottom: 40px;
  .tab-bar {
    overflow: hidden;

    .active {
      color: #409eff;
    }

    > span {
      float: left;
      margin-right: 12px;
      line-height: 40px;
      text-align: center;
      text-decoration: none;
      color: #000;
      cursor: pointer;
      font-size: 14px;
    }
  }
  .search-btn {
    position: absolute;
  }

  .tool-btn {
    position: absolute;
    right: 20px;
    top: 0;
  }
}
</style>
